import "./App.css";

function App() {
  return (
    <div className="App">
      <div
        style={{
          position: "absolute",
          overflow: "hidden",
          width: "100vw",
          height: "100vh",
          zIndex: -1,
        }}
      >
        <svg
          height="100%"
          width="100%"
          preserveAspectRatio="none"
          viewBox="0 0 1440 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0H1440V800H0V0Z" fill="#ededed" />
          <path d="M628 0H1440V800H985.5L628 0Z" fill="#2C2C2C" />
        </svg>
      </div>
      <div style={{ textAlign: "left" }}>Hi, I'm Connor</div>
    </div>
  );
}

export default App;
